import { FormEvent, MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  trendingDownOutline,
  receiptOutline,
  paperPlaneOutline,
  thumbsUpOutline,
  closeCircle,
  syncOutline,
  helpCircleOutline,
  addOutline,
  closeOutline,
  filterOutline,
  boatOutline,
  locationOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tShipments } from "../../store/types/shipping.types";
import { tOverview } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import Header from "../../components/Header/Header";
import ShipmentActions from "../../components/ShipmentActions/ShipmentActions";
import Spinner from "../../Loaders/Spinner/Spinner";

import {
  assertNotNull,
  copyData,
  getDate,
  isNumber,
  roundDP,
} from "../../utils/func";

import { DATE_RANGES } from "../../data";
import { updateUserData } from "../../store/userReducer";

declare global {
  interface Window {
    PaystackPop: any;
  }
}

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, accessToken } = useSelector((state: tRootState) => state.user);
  const { overview: cachedOverview, shippingTypes } = useSelector(
    (state: tRootState) => state.cache
  );

  assertNotNull(user);
  assertNotNull(cachedOverview);

  const { fetchOverview, fetchProfile } = useData();

  const [dateRange, setDateRange] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [shippingType, setShippingType] = useState("");

  const [message, setMessage, clearMessage] = useAlert();

  const [showFilterModal, setShowFilterModal] = useState(false);

  const [overview, setOverview] = useState<tOverview>(cachedOverview);
  const [shipments, setShipments] = useState<tShipments>(overview.shipments);

  const [showSpinner, setShowSpinner] = useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const [showTopupModal, setShowTopupModal] = useState(false);
  const [topupAmount, setTopupAmount] = useState<number | string>("");
  const [topupMessage, setTopupMessage, clearTopupMessage] = useAlert();

  const validateTopup = async (transactionId: string) => {
    setShowSpinner(true);

    let success = false;

    const currentDate = Date.now();

    while (Date.now() < currentDate + 60000 && !success) {
      try {
        const response = await api_client({
          url: `/wallet/validate-topup/${transactionId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.data.data.transaction_status === "CONFIRMED") {
          const profile = await api_client({
            url: `/auth/profile`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          dispatch(updateUserData(profile.data.data));

          success = true;

          break;
        }
      } catch (error) {
        continue;
      }
    }

    setShowSpinner(false);

    if (!success) {
      setError("Error validating topup");
    } else {
      setSuccess({
        title: "Topup Successful",
        message: "Your wallet top up is successful",
      });
    }
  };

  const initiateTopup = (transaction: {
    transaction_id: string;
    reference: string;
    email: string;
    currency: string;
    amount: number;
    custom_fields: {}[];
  }) => {
    let handler = window.PaystackPop.setup({
      key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEK,
      email: transaction.email,
      amount: transaction.amount,
      currency: transaction.currency,
      ref: transaction.reference,
      metadata: {
        custom_fields: transaction.custom_fields,
      },
      onClose: function () {
        setError("Topup transaction closed");
      },
      callback: function (response: { reference: string }) {
        validateTopup(transaction.transaction_id);
      },
    });
    handler.openIframe();
  };

  const topup = (amount: number) => {
    setShowSpinner(true);

    api_client({
      url: "/wallet/initiate-topup",
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        Amount: amount,
      },
    })
      .then((res) => {
        initiateTopup(res.data.data);
      })
      .catch((err) => {
        setError("An error occured. Try again");
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const topupHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!topupAmount)
      return setTopupMessage("warning", "Topup amount is required");

    setShowTopupModal(false);

    topup(+topupAmount);
  };

  const handleSearch = (e: MouseEvent<HTMLButtonElement>) => {
    if (dateRange === "CUSTOM" && (!startDate || !endDate))
      return setMessage(
        "warning",
        "Start date and end date is required for custom filtering"
      );

    setShowFilterModal(false);

    setShowSpinner(true);

    api_client({
      url: `/shipments/overview?1=1${
        dateRange && dateRange !== "CUSTOM" ? `&dateRange=${dateRange}` : ""
      }${dateRange === "CUSTOM" ? `&dateRange=${startDate}:${endDate}` : ""}${
        shippingType ? `&shipmentType=${shippingType}` : ""
      }`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
    })
      .then((res) => {
        setOverview(res.data.data);
      })
      .catch((err) => {
        // do nothing
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  useEffect(() => {
    clearTopupMessage();
  }, [clearTopupMessage, topupAmount]);

  useEffect(() => {
    setShipments(overview.shipments);
  }, [overview]);

  useEffect(() => {
    clearMessage();
  }, [dateRange, startDate, endDate, shippingType, clearMessage]);

  useEffect(() => {
    fetchProfile();
    fetchOverview().then((data) => {
      if (typeof data !== "string") setOverview(data as any);
    });
  }, [fetchOverview, fetchProfile]);

  return (
    <>
      {showSpinner ? <Spinner /> : null}

      {error ? (
        <div>
          <div className="success-modal">
            <div className="icon warning">
              <span className="body"></span>
              <span className="dot"></span>
            </div>
            <p className="text-center">{error}</p>
          </div>
          <div className="overlay" onClick={() => setError("")}></div>
        </div>
      ) : null}

      {success ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>{success.title}</h3>
            <p className="text-center">{success.message}</p>
            <div className="success-modal__btns">
              <button className="btn" onClick={() => setSuccess(null)}>
                Close
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setSuccess(null)}></div>
        </div>
      ) : null}

      <div>
        <form
          className={cls("modal modal--xs", showTopupModal && "modal--open")}
          onSubmit={topupHandler}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Wallet Topup</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => setShowTopupModal(false)}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              <div className="form-group">
                <label>
                  Amount<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="Enter amount"
                  value={topupAmount}
                  onChange={(e) =>
                    e.target.value
                      ? isNumber(e.target.value)
                        ? setTopupAmount(e.target.value)
                        : null
                      : setTopupAmount("")
                  }
                />
              </div>
              {topupMessage}
            </div>
          </div>
          <div className="modal__footer">
            <div></div>
            <div>
              <button className="btn btn--primary" type="submit">
                Topup
              </button>
            </div>
          </div>
        </form>
        {showTopupModal ? (
          <div
            className="overlay"
            onClick={() => setShowTopupModal(false)}
          ></div>
        ) : null}
      </div>

      <div>
        <div
          className={cls("modal modal--xs", showFilterModal && "modal--open")}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Filter Overview</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => setShowFilterModal(false)}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              <div className="form-group">
                <label>Pickup Range</label>
                <select
                  className="form-select"
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                >
                  <option value="">-- Select pickup range --</option>
                  {DATE_RANGES.map((range) => (
                    <option value={range.key} key={range.key}>
                      {range.value}
                    </option>
                  ))}
                </select>
              </div>
              {dateRange === "CUSTOM" ? (
                <div className="form-grid">
                  <div className="form-group">
                    <label>
                      Start Date <span>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-input"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      End Date <span>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-input"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              ) : null}
              <div className="form-group">
                <label>Shipment Type</label>
                <select
                  className="form-select"
                  value={shippingType}
                  onChange={(e) => setShippingType(e.target.value)}
                >
                  <option value="">-- Select Shipment Type --</option>
                  {shippingTypes.map((shippType) => (
                    <option value={shippType} key={shippType}>
                      {shippType}
                    </option>
                  ))}
                </select>
              </div>
              {message}
              <button className="button" onClick={handleSearch}>
                <IonIcon icon={filterOutline} /> Filter Overview
              </button>
            </div>
          </div>
        </div>
        {showFilterModal ? (
          <div
            className="overlay"
            onClick={() => setShowFilterModal(false)}
          ></div>
        ) : null}
      </div>

      <Header />
      <section className="dashboard-header">
        <div className="dashboard-header__container container">
          <div className="dashboard-header__main">
            <h3 className="dashboard-header__heading">
              Welcome Back, {user.Name}
            </h3>
            <p className="dashboard-header__text">
              This is your Overview Report
            </p>
          </div>
          <div>
            <button className="btn" onClick={(e) => setShowFilterModal(true)}>
              <IonIcon icon={filterOutline} />
              Filter Overview
            </button>
          </div>
        </div>
      </section>
      <main className="main">
        <div className="container">
          <div className="dsection">
            <div className="dcard-main-grid">
              <div className="dcard dcard--success">
                <div className="dcard__main">
                  <p className="dcard__key">Wallet Balance</p>
                  <p className="dcard__value">
                    &#8358;{roundDP(user.Wallet, 2)}
                  </p>
                  <p
                    className="dcard__key text-info"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowTopupModal(true)}
                  >
                    TOP UP WALLET
                  </p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={trendingDownOutline} />
                </div>
              </div>
              <div className="dcard dcard--danger">
                <div className="dcard__main">
                  <p className="dcard__key">Total Shipments</p>
                  <p className="dcard__value">
                    {Object.values(overview.analyticsData).reduce(
                      (a, b) => a + b,
                      0
                    )}
                  </p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={boatOutline} />
                </div>
              </div>
              <div className="dcard dcard--info">
                <div className="dcard__main">
                  <p className="dcard__key">Total Contacts</p>
                  <p className="dcard__value">{overview.data.totalContacts}</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={locationOutline} />
                </div>
              </div>
            </div>
            <div className="info-cards">
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.CREATED}
                  </p>
                  <p className="dcard__key text-uppercase">Created</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={helpCircleOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData["PRE-TRANSIT"]}
                  </p>
                  <p className="dcard__key text-uppercase">Pre-Transit</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={receiptOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.TRANSIT}
                  </p>
                  <p className="dcard__key text-uppercase">Transit</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={paperPlaneOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.DELIVERED}
                  </p>
                  <p className="dcard__key text-uppercase">Delivered</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={thumbsUpOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.FAILED}
                  </p>
                  <p className="dcard__key text-uppercase">Failed</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={closeCircle} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.CANCELED}
                  </p>
                  <p className="dcard__key text-uppercase">Canceled</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={closeCircle} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.RETURNED}
                  </p>
                  <p className="dcard__key text-uppercase">Returned</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={syncOutline} />
                </div>
              </div>
            </div>
            <section>
              <div className="dheader">
                <h3 className="dheading">Recent Shipments</h3>
                <button
                  className="btn btn--primary"
                  onClick={() => navigate("/new-shipment")}
                >
                  <IonIcon icon={addOutline} />
                  New Shipment
                </button>
              </div>

              <div className="table-responsive shipments-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Item(Weight)</th>
                      <th>Pickup Date</th>
                      <th>Delivery Info</th>
                      <th>Amount (&#8358;)</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!shipments.length ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No shipments
                        </td>
                      </tr>
                    ) : null}
                    {shipments.map((shipment) => (
                      <tr key={shipment._id}>
                        <td>
                          <span onClick={() => copyData(shipment._id)}>
                            #{shipment._id.slice(shipment._id.length - 6)}
                          </span>
                        </td>
                        <td>
                          {shipment.Description}{" "}
                          {shipment.TotalWeight ? (
                            <>({roundDP(shipment.TotalWeight, 2)} KG)</>
                          ) : null}
                        </td>
                        <td>{getDate(shipment.PickupDate!)}</td>
                        <td>
                          <div>
                            <p>
                              <strong>Name:</strong> {shipment.Cosignee.Name}
                            </p>
                            <p>
                              <strong>Telephone:</strong>{" "}
                              {shipment.Cosignee.Telephone}
                            </p>
                            <p>
                              <strong>Address:</strong>{" "}
                              {shipment.Cosignee.Address},{" "}
                              {shipment.Cosignee.State},{" "}
                              {shipment.Cosignee.Country.Name}
                            </p>
                          </div>
                        </td>
                        <td>{roundDP(shipment.TotalIncomeCharge, 2)}</td>
                        <td title={shipment.LastActivity}>
                          {shipment.Status}
                          <br />({shipment.LastActivity.slice(0, 30)}
                          {shipment.LastActivity.length > 30 ? "..." : ""})
                        </td>
                        <td>
                          <ShipmentActions
                            shipment={shipment}
                            setShipments={setShipments}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="shipments">
                {shipments.map((shipment) => (
                  <div className="shipment" key={shipment._id}>
                    <div className="shipment__header">
                      <p>
                        <strong>Shipment:</strong> #
                        {shipment._id.slice(shipment._id.length - 6)}
                      </p>
                      <div className="shipment__right">
                        <span title={shipment.LastActivity}>
                          {shipment.Status}
                        </span>
                        <ShipmentActions
                          setShipments={setShipments}
                          shipment={shipment}
                        />
                      </div>
                    </div>
                    <div className="shipment__body">
                      <div className="mb-small">
                        <p>
                          <strong>Description:</strong> {shipment.Description}{" "}
                          {shipment.TotalWeight ? (
                            <>({roundDP(shipment.TotalWeight, 2)} KG)</>
                          ) : null}
                        </p>
                        {shipment.PickupDate ? (
                          <p>
                            <strong>Pickup Date:</strong>{" "}
                            {getDate(shipment.PickupDate)}
                          </p>
                        ) : null}
                      </div>
                      <div>
                        <p>
                          <strong>Name:</strong> {shipment.Cosignee.Name}
                        </p>
                        <p>
                          <strong>Telephone:</strong>{" "}
                          {shipment.Cosignee.Telephone}
                        </p>
                        <p>
                          <strong>Address:</strong> {shipment.Cosignee.Address}
                        </p>
                      </div>
                      <p>
                        <strong>Amount:</strong> &#8358;{" "}
                        {roundDP(shipment.TotalIncomeCharge, 2)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(Dashboard);
