import { useState, useEffect, useRef, FormEvent } from "react";
import { Link } from "react-router-dom";

import api_client from "../../api/client";

import useAlert from "../../hooks/useAlert/useAlert";

import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import dmxLogo from "../../assets/img/dmx-logo-colored.png";

const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const [success, setSuccess] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailAddress) return setMessage("warning", "Enter your email address");

    submitBtnRef.current.setAttribute("disabled", "disabled");
    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;

    api_client({
      url: "/users/forgot-password",
      method: "POST",
      data: {
        EmailAddress: emailAddress,
      },
    })
      .then((res) => {
        setSuccess({
          title: "Successful!",
          message: `A password reset link has been sent to your email(${emailAddress}) as well as a token to reset your password`,
        });
        setEmailAddress("");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Continue";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [emailAddress, clearMessage]);

  return (
    <AuthLayout>
      {success ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>{success.title}</h3>
            <p className="text-center">{success.message}</p>
            <div className="success-modal__btns">
              <button className="btn" onClick={() => setSuccess(null)}>
                Close
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setSuccess(null)}></div>
        </div>
      ) : null}

      <main className="auth__main">
        <div className="auth__logo-box">
          <img src={dmxLogo} alt="" className="auth__logo" />
        </div>
        <div className="auth__header">
          <h3 className="auth__heading">Forgot Password?</h3>
          <p className="auth__sub-heading">
            Enter your email address and we would send you a password reset link
            and code to change your password.
          </p>
        </div>
        <form className="auth__form" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              placeholder="Enter email address"
              className="form-input"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
          <button
            className="button mt-small"
            disabled={!emailAddress}
            ref={submitBtnRef}
          >
            Continue
          </button>
          {message}
        </form>
        <p className="mt-small text-center">
          Remember your password?{" "}
          <Link to="/login" className="link">
            Login
          </Link>
        </p>
      </main>
    </AuthLayout>
  );
};

export default ForgotPassword;
