import { useState, useEffect, useRef, FormEvent } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import useAlert from "../../hooks/useAlert/useAlert";

import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import dmxLogo from "../../assets/img/dmx-logo-colored.png";
import Preloader from "../../components/Preloader/Preloader";

const ResetPassword = () => {
  const navigate = useNavigate();

  const token = useSearchParams()[0].get("token");

  const [loading, setLoading] = useState(true);

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!code || !password || !confirmPassword)
      return setMessage("warning", "Fill in all fields");

    if (password !== confirmPassword)
      return setMessage("warning", "Passwords do not match");

    submitBtnRef.current.setAttribute("disabled", "disabled");
    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;

    api_client({
      url: "/users/reset-password",
      method: "PATCH",
      data: {
        Token: token,
        Code: code,
        NewPassword: password,
      },
    })
      .then((res) => {
        setMessage(
          `success`,
          `Your password has been reset successfully kindly login with your new credentials. <span class="fas fa-spinner fa-spin"></span> Redirecting to login ...`
        );

        const interval = window.setInterval(() => {
          navigate("/login");
          window.clearInterval(interval);
        }, 3000);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          if (err.response.data.message === "Reset Password Token Expired") {
            setMessage(
              `warning`,
              `Reset password token has expired this can be due to 5 failed attempts or expiration after 30 minutes. <span class="fas fa-spinner fa-spin"></span> Redirecting to forgot password ...`
            );
            const interval = window.setInterval(() => {
              navigate("/forgot-password");
              window.clearInterval(interval);
            }, 3000);
          } else {
            setMessage("warning", err.response.data.message);
          }
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Login";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [code, password, confirmPassword, clearMessage]);

  useEffect(() => {
    if (!token) return;

    api_client({
      url: `/users/forgot-password/${token}/verify`,
      method: "GET",
    })
      .then((res) => {
        // do nothing - verified
      })
      .catch((err) => {
        navigate("/not-found");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token, navigate]);

  if (!token) return <Navigate to="/not-found" />;

  if (loading) return <Preloader />;

  return (
    <AuthLayout>
      <main className="auth__main">
        <div className="auth__logo-box">
          <img src={dmxLogo} alt="" className="auth__logo" />
        </div>
        <div className="auth__header">
          <h3 className="auth__heading">Reset Password</h3>
          <p className="auth__sub-heading">
            To reset password, create a new password and input the code sent to
            your email to complete the process.
          </p>
        </div>
        <form className="auth__form" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Code</label>
            <input
              type="text"
              placeholder="Enter reset password code"
              className="form-input"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>New Password</label>
            <div className="form-input-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword((sp) => !sp)}
              >
                <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
              </button>
            </div>
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <div className="form-input-group">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword((sp) => !sp)}
              >
                <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
              </button>
            </div>
            {password && confirmPassword && password !== confirmPassword ? (
              <span className="text-danger">Passwords do not match</span>
            ) : null}
          </div>
          <button
            className="button mt-small"
            disabled={
              !code ||
              !password ||
              !confirmPassword ||
              password !== confirmPassword
            }
            ref={submitBtnRef}
          >
            Reset Password
          </button>
          {message}
        </form>
      </main>
    </AuthLayout>
  );
};

export default ResetPassword;
