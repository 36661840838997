import { useState, useEffect, MouseEvent, useRef, FormEvent } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  cloudDownloadOutline,
  cloudUpload,
  documentTextOutline,
  searchOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";

import { tContact, tShipmentType } from "../../store/types/shipping.types";
import useSelectContact, {
  tSelectContactData,
} from "../../hooks/useSelectContact/useSelectContact";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";

import Header from "../../components/Header/Header";
import SearchBox from "../../components/SearchBox/SearchBox";

const ShipmentsImport = () => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const shippingTypes = useSelector(
    (state: tRootState) => state.cache.shippingTypes
  );

  // Shipment Type
  const [shipmentType, setShipmentType] = useState<tShipmentType | "">("Local");

  // Contact
  const selectContactData: tSelectContactData = {};

  const [selectContact, openSelectContact] =
    useSelectContact(selectContactData);

  const [pickupContact, setPickupContact] = useState<tContact | null>(null);

  const [pickupContactSearch, setPickupContactSearch] = useState("");
  const [pickupContactSearchFocus, setPickupContactSearchFocus] =
    useState(false);

  const pickupContactSearchRef = useRef<HTMLInputElement>(
    {} as HTMLInputElement
  );

  // Dates
  const [pickupDate, setPickupDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");

  // File
  const [file, setFile] = useState<File | null>(null);

  const fileInputRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const [message, set_message, clear_message] = useAlert();

  const handleFileInputChange = (e: FormEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;

    if (files?.length && files[0].type === "text/csv") setFile(files[0]);

    fileInputRef.current.value = "";
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.target! as HTMLButtonElement;

    if (
      !shipmentType ||
      !pickupContact ||
      !pickupDate ||
      !deliveryDate ||
      !file
    )
      return set_message("warning", "Fill in all fields");

    target.setAttribute("disabled", "disabled");
    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;

    const formData = new FormData();

    formData.append("shipmentType", shipmentType);
    formData.append("pickupContact", pickupContact._id);
    formData.append("pickupDate", pickupDate);
    formData.append("deliveryDate", deliveryDate);
    formData.append("file", file);

    api_client({
      method: "POST",
      url: "/shipments/import",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        navigate(`/import/${res.data.data._id}`);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          set_message(
            "warning",
            err.response.data.message +
              (err.response.data.data
                ? `. <a href="${err.response.data.data}" target="_blank"><span class="fas fa-download"></span> Download Validations</a>`
                : "")
          );
        } else {
          set_message("error", err.message);
        }
      })
      .finally(() => {
        if (target) {
          target.removeAttribute("disabled");
          target.innerHTML = "Import Shipments";
        }
      });
  };

  useEffect(() => {
    clear_message();
  }, [shipmentType, pickupDate, deliveryDate, file, clear_message]);

  useEffect(() => {
    if (pickupContact) setPickupContactSearch(pickupContact.Name);
  }, [pickupContact]);

  return (
    <>
      {selectContact}

      <input
        type="file"
        style={{ display: "none" }}
        multiple
        ref={fileInputRef}
        onChange={handleFileInputChange}
        accept=".csv"
      />
      <Header />
      <main className="main">
        <div className="container">
          <div className="shipments-import">
            <div className="shipments-import__header">
              <h3 className="shipments-import__heading">Import shipment</h3>
              <p>Import your shipments using csv formatted data</p>
              <p>
                <a
                  href={`${process.env.REACT_APP_BACKEND_BASE_URL}/shipments/downloads/import-csv-format.csv`}
                  rel="noreferrer"
                  target="_blank"
                  className="shipments-import__download"
                >
                  <IonIcon icon={cloudDownloadOutline} />
                  Download CSV
                </a>
              </p>
            </div>
            <div className="shipments-import__form">
              <div className="form-group-horizontal">
                <label>Shipment Type</label>
                <div className="form-group-horizontal__main">
                  <select
                    className="form-select"
                    value={shipmentType}
                    onChange={(e) =>
                      setShipmentType(e.target.value as tShipmentType)
                    }
                  >
                    <option value="">-- Select Shipment Type --</option>
                    {shippingTypes.map((shpType) => (
                      <option key={shpType}>{shpType}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group-horizontal">
                <label>Location</label>
                <div className="form-group-horizontal__main">
                  <select className="form-select">
                    <option value="">-- Select Location --</option>
                    <option>Lagos</option>
                    <option>Federal Capital Territory</option>
                  </select>
                </div>
              </div>
              <div className="form-group-horizontal">
                <label>Pickup Contact</label>
                <div className="form-group-horizontal__main">
                  <div className="form-group">
                    {pickupContact ? (
                      <div className="label-flex">
                        <span></span>
                        <span
                          onClick={() => {
                            setPickupContact(null);

                            setTimeout(() => {
                              pickupContactSearchRef.current.focus();
                            }, 0);
                          }}
                        >
                          Clear Selection
                        </span>
                      </div>
                    ) : null}
                    {pickupContact ? (
                      <div className="form-info">
                        <p>
                          <strong>Name:</strong> {pickupContact.Name}
                        </p>
                        <p>
                          <strong>Company:</strong> {pickupContact.Company}
                        </p>
                        <p>
                          <strong>Address:</strong> {pickupContact.Address}{" "}
                          {pickupContact.Address2
                            ? `, ${pickupContact.Address2}`
                            : ""}
                          {pickupContact.Address3
                            ? `, ${pickupContact.Address3}`
                            : ""}
                          , {pickupContact.City}, {pickupContact.State},{" "}
                          {pickupContact.Country.Name}
                        </p>
                        {pickupContact.EmailAddress ? (
                          <p>
                            <strong>Email address:</strong>{" "}
                            {pickupContact.EmailAddress}
                          </p>
                        ) : null}
                        <p>
                          <strong>Telephone</strong> {pickupContact.Telephone}
                        </p>
                      </div>
                    ) : null}
                    <div
                      className={cls("input-group", pickupContact && "hidden")}
                    >
                      <input
                        type="text"
                        placeholder="Select Pickup Contact"
                        value={pickupContactSearch}
                        ref={pickupContactSearchRef}
                        onChange={(e) => setPickupContactSearch(e.target.value)}
                        onFocus={(e) => {
                          setPickupContactSearchFocus(true);
                        }}
                        onBlur={() => {
                          setPickupContactSearchFocus(false);
                        }}
                      />
                      <button
                        onClick={() => openSelectContact(setPickupContact)}
                      >
                        <IonIcon icon={searchOutline} />
                      </button>
                    </div>
                    <SearchBox
                      focus={pickupContactSearchFocus}
                      url={`/contacts?page=1&division=8&search={{search}}`}
                      search={pickupContactSearch}
                      displayKey="Name"
                      clickHandler={setPickupContact}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-horizontal">
                <label>Pickup Date</label>
                <div className="form-group-horizontal__main">
                  <input
                    type="date"
                    className="form-input"
                    value={pickupDate}
                    onChange={(e) => setPickupDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group-horizontal">
                <label>Delivery Date</label>
                <div className="form-group-horizontal__main">
                  <input
                    type="date"
                    className="form-input"
                    value={deliveryDate}
                    onChange={(e) => setDeliveryDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group-horizontal">
                <label>Choose CSV File</label>
                <div className="form-group-horizontal__main">
                  <div
                    className="file-drop"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <IonIcon icon={file ? documentTextOutline : cloudUpload} />
                    {file ? (
                      <p className="file-drop__info-1">
                        Selected File: {file.name} <span>change</span>
                      </p>
                    ) : (
                      <p className="file-drop__info-1">
                        Drop your file here, or <span>browse</span>
                      </p>
                    )}
                    <p className="file-drop__info-2">
                      Supports just .csv files
                    </p>
                  </div>
                </div>
              </div>
              <div className=" form-group-horizontal">
                <label></label>
                <div className="form-group-horizontal__main">{message}</div>
              </div>
              <div className=" form-group-horizontal">
                <label></label>
                <div className="form-group-horizontal__main">
                  <button className="button" onClick={handleSubmit}>
                    Import Shipments
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default withAuth(ShipmentsImport);
