import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  checkmarkOutline,
  searchOutline,
  createOutline,
  trashOutline,
  addOutline,
  chevronDownOutline,
  alertCircle,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import {
  tCargo,
  tContact,
  tPackagingType,
  tShipmentCargo,
  tShipmentCargos,
  tShipmentType,
} from "../../store/types/shipping.types";

import { getInputDate, isNumber } from "../../utils/func";
import { tCountry, tCurrency } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useSelectContact from "../../hooks/useSelectContact/useSelectContact";
import useSelectBox from "../../hooks/useSelectBox/useSelectBox";
import useSelectCargo from "../../hooks/useSelectCargo/useSelectCargo";

import Header from "../../components/Header/Header";
import SearchBox from "../../components/SearchBox/SearchBox";
import Spinner from "../../Loaders/Spinner/Spinner";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

export function compSelectHandler<T extends { _id: string }>(
  arr: T[],
  setState: React.Dispatch<React.SetStateAction<T | null>>
): (e: ChangeEvent<HTMLSelectElement>) => void {
  return (e: ChangeEvent<HTMLSelectElement>) =>
    setState(arr.find((dt) => dt._id === e.target.value) || null);
}

declare global {
  interface Window {
    google: any;
  }
}

const NewShipping = () => {
  const navigate = useNavigate();

  const { id: shipmentId } = useParams();

  const { accessToken, user } = useSelector((state: tRootState) => state.user);

  const { shippingTypes, currencies, countries, countriesSelectData } =
    useSelector((state: tRootState) => state.cache);

  const { fetchCurrencies, fetchCountries } = useData();

  const [selectContact, openSelectContact] = useSelectContact();
  const [selectCargo, openSelectCargo] = useSelectCargo();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // loading
  const [loading, setLoading] = useState(!!shipmentId);

  // Basic Information
  const [basicInfoIssues, setBasicInfoIssues] = useState<string[]>([]);
  const [basicInfoValid, setBasicInfoValid] = useState(false);

  const [shipmentDescription, setShipmentDescription] = useState("");
  const [shipmentType, setShipmentType] = useState<tShipmentType | "">("");
  const [declaredValue, setDeclaredValue] = useState<number | string>("");
  const [declaredValueCurrency, setDeclaredValueCurrency] =
    useState<tCurrency | null>(null);
  const [cargoInsurance, setCargoInsurance] = useState<number | string>("");

  // Origin Information
  const [originIssues, setOriginIssues] = useState<string[]>([]);
  const [originValid, setOriginValid] = useState(false);

  const [selectedOrigin, setSelectedOrigin] = useState<tContact | null>(null);

  const [originNameFocus, setOriginNameFocus] = useState(false);
  const [originName, setOriginName] = useState("");

  const [originCompany, setOriginCompany] = useState("");

  const originAddressRef = useRef<HTMLInputElement | null>(null);
  const [originAddress, setOriginAddress] = useState("");

  const [originAddress2, setOriginAddress2] = useState("");
  const [originAddress3, setOriginAddress3] = useState("");
  const [originSuburb, setOriginSuburb] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [originPostalCode, setOriginPostalCode] = useState("");
  const [originState, setOriginState] = useState("");
  const [originCountry, setOriginCountry] = useState<tCountry | null>(null);
  const [originEmailAddress, setOriginEmailAddress] = useState("");
  const [
    originTelephoneCountrySelectBox,
    selectedOriginTelephoneCountryId,
    openOriginTelephoneCountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [originTelephoneCountry, setOriginTelephoneCountry] =
    useState<tCountry | null>(null);
  const [originTelephone, setOriginTelephone] = useState<number | "">("");
  const [
    originTelephone2CountrySelectBox,
    selectedOriginTelephone2CountryId,
    openOriginTelephone2CountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [originTelephone2Country, setOriginTelephone2Country] =
    useState<tCountry | null>(null);
  const [originTelephone2, setOriginTelephone2] = useState<number | "">("");
  const [originIsResidential, setOriginIsResidential] = useState(false);
  const [originSaveContact, setOriginSaveContact] = useState(false);

  const [pickupDate, setPickupDate] = useState("");

  // Destination Information
  const [destinationIssues, setDestinationIssues] = useState<string[]>([]);
  const [destinationValid, setDestinationValid] = useState(false);

  const [selectedDestination, setSelectedDestination] =
    useState<tContact | null>(null);

  const [destinationNameFocus, setDestinationNameFocus] = useState(false);
  const [destinationName, setDestinationName] = useState("");

  const [destinationCompany, setDestinationCompany] = useState("");

  const destinationAddressRef = useRef<HTMLInputElement | null>(null);
  const [destinationAddress, setDestinationAddress] = useState("");

  const [destinationAddress2, setDestinationAddress2] = useState("");
  const [destinationAddress3, setDestinationAddress3] = useState("");
  const [destinationSuburb, setDestinationSuburb] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [destinationPostalCode, setDestinationPostalCode] = useState("");
  const [destinationState, setDestinationState] = useState("");
  const [destinationCountry, setDestinationCountry] = useState<tCountry | null>(
    null
  );
  const [destinationEmailAddress, setDestinationEmailAddress] = useState("");
  const [
    destinationTelephoneCountrySelectBox,
    selectedDestinationTelephoneCountryId,
    openDestinationTelephoneCountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [destinationTelephoneCountry, setDestinationTelephoneCountry] =
    useState<tCountry | null>(null);
  const [destinationTelephone, setDestinationTelephone] = useState<number | "">(
    ""
  );
  const [
    destinationTelephone2CountrySelectBox,
    selectedDestinationTelephone2CountryId,
    openDestinationTelephone2CountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [destinationTelephone2Country, setDestinationTelephone2Country] =
    useState<tCountry | null>(null);
  const [destinationTelephone2, setDestinationTelephone2] = useState<
    number | ""
  >("");
  const [destinationIsResidential, setDestinationIsResidential] =
    useState(false);
  const [destinationSaveContact, setDestinationSaveContact] = useState(false);

  const [deliveryDate, setDeliveryDate] = useState("");

  // Cargo Details
  const [cargoIssues1, setCargoIssues1] = useState<string[]>([]);
  const [cargoIssues1Valid, setCargoIssues1Valid] = useState(false);
  const [cargoIssues2, setCargoIssues2] = useState<string[]>([]);
  const [cargoIssues2Valid, setCargoIssues2Valid] = useState(false);

  const [shipmentCargoIndex, setShipmentCargoIndex] = useState<number | null>(
    null
  );
  const [shipmentCargos, setShipmentCargos] = useState<tShipmentCargos>([]);

  const [selectedCargo, setSelectedCargo] = useState<tCargo | null>(null);

  const [cargoDescriptionFocus, setCargoDescriptionFocus] = useState(false);
  const [cargoDescription, setCargoDescription] = useState("");

  const [cargoItemCode, setCargoItemCode] = useState("");

  const [cargoQuantity, setCargoQuantity] = useState<number | "">("");
  const [cargoDeclaredValue, setCargoDeclaredValue] = useState<number | string>(
    ""
  );

  const [cargoPackagingType, setCargoPackagingType] =
    useState<tPackagingType | null>(null);

  const [cargoWeight, setCargoWeight] = useState<number | string>("");
  const [cargoWeightMeasure, setCargoWeightMeasure] = useState("KG");
  const [cargoLength, setCargoLength] = useState<number | string>("");
  const [cargoBreadth, setCargoBreadth] = useState<number | string>("");
  const [cargoHeight, setCargoHeight] = useState<number | string>("");

  const [alertMessages, setAlertMessages] = useState<string[]>([]);
  const [rateInfo, setRateInfo] = useState<{
    rate: number;
    pickupDate: string;
    totalWeight: number;
  } | null>(null);

  const [triggerAddressField, setTriggerAddressField] = useState(false);

  const newShipment = useRef("");

  const createShipment = () => {
    setShowConfirmationModal(false);

    setShowSpinner(true);

    // basic info
    const shipmentData: any = {
      User: user!._id,
      Description: shipmentDescription,
      Type: shipmentType,
      ValueInsuranceCurrency: declaredValueCurrency!._id,
      DeclaredValue: declaredValue,
      PickupDate: pickupDate,
      DeliveryDate: deliveryDate,
    };

    if (cargoInsurance) shipmentData.CargoInsurance = cargoInsurance;

    // origin
    let origin: any;
    if (selectedOrigin && !originChanged()) {
      origin = selectedOrigin._id;
    } else {
      origin = {
        Name: originName,
        Company: originCompany,
        Address: originAddress,
        City: originCity,
        State: originState,
        Country: originCountry!._id,
        TelephoneCountry: originTelephoneCountry!._id,
        Telephone: `${originTelephoneCountry!.TelephoneCode}${originTelephone}`,
        Residential: originIsResidential,
        Saved: originSaveContact,
      };

      if (originAddress2) origin.Address2 = originAddress2;
      if (originAddress3) origin.Address3 = originAddress3;
      if (originSuburb) origin.Suburb = originSuburb;
      if (originPostalCode) origin.PostalCode = originPostalCode;
      if (originEmailAddress) origin.EmailAddress = originEmailAddress;
      if (originTelephone2Country)
        origin.Telephone2Country = originTelephone2Country._id;
      if (originTelephone2) origin.Telephone2 = originTelephone2;
    }

    shipmentData.Shipper = origin;

    // destination
    let destination: any;
    if (selectedDestination && !destinationChanged()) {
      destination = selectedDestination._id;
    } else {
      destination = {
        Name: destinationName,
        Company: destinationCompany,
        Address: destinationAddress,
        City: destinationCity,
        State: destinationState,
        Country: destinationCountry!._id,
        TelephoneCountry: destinationTelephoneCountry!._id,
        Telephone: `${
          destinationTelephoneCountry!.TelephoneCode
        }${destinationTelephone}`,
        Residential: destinationIsResidential,
        Saved: destinationSaveContact,
      };

      if (destinationAddress2) destination.Address2 = destinationAddress2;
      if (destinationAddress3) destination.Address3 = destinationAddress3;
      if (destinationSuburb) destination.Suburb = destinationSuburb;
      if (destinationPostalCode) destination.PostalCode = destinationPostalCode;
      if (destinationEmailAddress)
        destination.EmailAddress = destinationEmailAddress;
      if (destinationTelephone2Country)
        destination.Telephone2Country = destinationTelephone2Country._id;
      if (destinationTelephone2) destination.Telephone2 = destinationTelephone2;
    }

    shipmentData.Cosignee = destination;

    // cargo details
    if (shipmentType === "Local") {
      shipmentData.Cargos = [];
    } else {
      shipmentData.Cargos = shipmentCargos.map((cargo) => {
        const cargoData: any = {
          Description: cargo.cargoDescription,
          Quantity: cargo.cargoQuantity,
          DeclaredValue: cargo.cargoDeclaredValue,
          DeclaredValueCurrency: declaredValueCurrency!._id,
          Weight: cargo.cargoWeight,
          WeightMeasure: cargo.cargoWeightMeasure,
          Length: cargo.cargoLength,
          Breadth: cargo.cargoBreadth,
          Height: cargo.cargoHeight,
        };

        if (cargo.cargoItemCode) cargoData.ItemCode = cargo.cargoItemCode;
        if (cargo.cargoPackagingType)
          cargoData.PackagingType = cargo.cargoPackagingType._id;

        return cargoData;
      });
    }

    api_client({
      url: shipmentId
        ? `/shipments/${shipmentId}/customer`
        : "/shipments/customer",
      method: shipmentId ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(shipmentData),
    })
      .then((res) => {
        newShipment.current = res.data.data._id;
        setShowSuccessModal(true);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setAlertMessages([err.response.data.message]);
        } else {
          setAlertMessages(["Error creating shipment. Try again"]);
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const searchRate = () => {
    if (!originValid)
      return setAlertMessages([
        "Fill in Origin(Shipper) information correctly",
      ]);
    if (!destinationValid)
      return setAlertMessages([
        "Fill in Destination(Cosignee) information correctly",
      ]);
    if (!cargoIssues1Valid)
      return setAlertMessages(["Fill in Cargo Details information correctly"]);

    setShowSpinner(true);

    api_client({
      method: "POST",
      // url: `/shipments/rate/${carrier._id}`,
      url: `/shipments/rate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        pickupDate: pickupDate,
        shipper: {
          Country: originCountry?._id,
          State: originState,
          City: originCity,
          PostalCode: originPostalCode,
          Address: originAddress,
          Address2: originAddress2,
          Address3: originAddress3,
        },
        receiver: {
          Country: destinationCountry?._id,
          State: destinationState,
          City: destinationCity,
          PostalCode: destinationPostalCode,
          Address: destinationAddress,
          Address2: destinationAddress2,
          Address3: destinationAddress3,
        },
        cargos: shipmentCargos.map((cargo) => ({
          Weight: cargo.cargoWeight,
          Quantity: cargo.cargoQuantity,
          Length: cargo.cargoLength,
          Breadth: cargo.cargoBreadth,
          Height: cargo.cargoHeight,
        })),
      }),
    })
      .then((res) => {
        setRateInfo(res.data.data);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setAlertMessages([err.response.data.message]);
        } else {
          setAlertMessages(["Error fetching rate. Try again"]);
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  // Cargo Methods
  const addUpdateShipmentCargo = () => {
    if (!cargoIssues2Valid) return setAlertMessages(cargoIssues2);

    const newShipmentCargo: tShipmentCargo = {
      cargoDescription,
      cargoItemCode,
      cargoQuantity: +cargoQuantity,
      cargoDeclaredValue: +cargoDeclaredValue,
      cargoPackagingType,
      cargoWeight: +cargoWeight,
      cargoWeightMeasure,
      cargoLength: +cargoLength || 1,
      cargoBreadth: +cargoBreadth || 1,
      cargoHeight: +cargoHeight || 1,
    };

    if (typeof shipmentCargoIndex === "number") {
      setShipmentCargos((shpCargos) =>
        shpCargos.map((shpCargo, i) =>
          i === shipmentCargoIndex ? newShipmentCargo : shpCargo
        )
      );
      setShipmentCargoIndex(null);
    } else {
      setShipmentCargos((shpCargos) => [...shpCargos, newShipmentCargo]);
    }

    setCargoDescription("");
    setCargoItemCode("");
    setCargoQuantity("");
    setCargoDeclaredValue("");
    setCargoPackagingType(null);
    setCargoWeight("");
    setCargoWeightMeasure("KG");
    setCargoLength("");
    setCargoBreadth("");
    setCargoHeight("");
  };

  const removeShipmentCargo = (index: number) => {
    setShipmentCargos((shpCargos) =>
      shpCargos.filter((shpCargo, i) => i !== index)
    );
  };

  const destinationChanged = () => {
    if (!selectedDestination) return;

    if (selectedDestination.Name !== destinationName) return true;
    if (selectedDestination.Company !== destinationCompany) return true;
    if (selectedDestination.Address !== destinationAddress) return true;
    if ((selectedDestination.Address2 || "") !== destinationAddress2)
      return true;
    if ((selectedDestination.Address3 || "") !== destinationAddress3)
      return true;
    if ((selectedDestination.Suburb || "") !== destinationSuburb) return true;
    if ((selectedDestination.PostalCode || "") !== destinationPostalCode)
      return true;
    if (selectedDestination.Country._id !== destinationCountry?._id)
      return true;
    if (selectedDestination.State !== destinationState) return true;
    if (selectedDestination.City !== destinationCity) return true;
    if (selectedDestination.EmailAddress !== destinationEmailAddress)
      return true;
    if (
      selectedDestination.TelephoneCountry._id !==
      destinationTelephoneCountry?._id
    )
      return true;
    if (
      +selectedDestination.Telephone.slice(
        selectedDestination.Telephone.length - 10
      ) !== destinationTelephone
    )
      return true;

    if (
      selectedDestination.Telephone2Country &&
      selectedDestination.Telephone2
    ) {
      if (
        (selectedDestination.Telephone2Country?._id || null) !==
        destinationTelephone2Country?._id
      )
        return true;
      if (
        (selectedDestination.Telephone2
          ? +selectedDestination.Telephone2.slice(
              selectedDestination.Telephone2.length - 10
            )
          : "") !== destinationTelephone2
      )
        return true;
    }

    return false;
  };

  const originChanged = () => {
    if (!selectedOrigin) return;

    if (selectedOrigin.Name !== originName) return true;
    if (selectedOrigin.Company !== originCompany) return true;
    if (selectedOrigin.Address !== originAddress) return true;
    if ((selectedOrigin.Address2 || "") !== originAddress2) return true;
    if ((selectedOrigin.Address3 || "") !== originAddress3) return true;
    if ((selectedOrigin.Suburb || "") !== originSuburb) return true;
    if ((selectedOrigin.PostalCode || "") !== originPostalCode) return true;
    if (selectedOrigin.Country._id !== originCountry?._id) return true;
    if (selectedOrigin.State !== originState) return true;
    if (selectedOrigin.City !== originCity) return true;
    if (selectedOrigin.EmailAddress !== originEmailAddress) return true;
    if (selectedOrigin.TelephoneCountry._id !== originTelephoneCountry?._id)
      return true;
    if (
      +selectedOrigin.Telephone.slice(selectedOrigin.Telephone.length - 10) !==
      originTelephone
    )
      return true;
    if (selectedOrigin.Telephone2Country && selectedOrigin.Telephone2) {
      if (
        (selectedOrigin.Telephone2Country?._id || null) !==
        originTelephone2Country?._id
      )
        return true;
      if (
        (selectedOrigin.Telephone2
          ? +selectedOrigin.Telephone2.slice(
              selectedOrigin.Telephone2.length - 10
            )
          : "") !== originTelephone2
      )
        return true;
    }

    return false;
  };

  // Validation useEffects

  // Basic Information Validation
  useEffect(() => {
    const data: string[] = [];

    if (!shipmentDescription) data.push("Shipment description is required");
    if (shipmentDescription.length > 70)
      data.push("Max length for shipment description is 70 characters"); // DHL Specific
    if (!shipmentType) data.push("Shipment type is required");

    if (!declaredValue) data.push("Declared value is required");
    if (!declaredValueCurrency)
      data.push("Declared value currency is required");

    setBasicInfoIssues(data);
    setBasicInfoValid(!data.length);
  }, [
    shipmentDescription,
    shipmentType,
    declaredValue,
    declaredValueCurrency,
    cargoInsurance,
  ]);

  // Origin Validation
  useEffect(() => {
    const data: string[] = [];

    if (!originName) data.push("Name is required");
    if (!originCompany) data.push("Company is required");
    if (!originAddress) data.push("Address is required");
    if (originAddress.length > 45)
      data.push("Max length for address is 45 characters"); // DHL Specific
    if (!originCity) data.push("City is required");
    if (!originState) data.push("State is required");
    if (!originCountry) data.push("Country is required");
    // if (!originEmailAddress) data.push("Email address is required");
    if (!originTelephoneCountry) data.push("Telephone Country is required");
    if (!originTelephone) data.push("Telephone is required");

    if (!pickupDate) data.push("Pickup date is required");

    const nigeria = countries.find((country) => country.Code === "NG") || null;

    if (originCountry && originCountry._id !== nigeria?._id)
      data.push("Origin country must be nigeria");

    if (
      originState &&
      !["Lagos", "Federal Capital Territory"].includes(originState)
    )
      data.push(
        "Origin state must either be Lagos or Federal Capital Territory"
      );

    setOriginIssues(data);
    setOriginValid(!data.length);
  }, [
    originName,
    originCompany,
    originAddress,
    originCity,
    originState,
    originCountry,
    originEmailAddress,
    originTelephoneCountry,
    originTelephone,
    selectedOrigin,
    selectedDestination,
    pickupDate,
    shipmentType,
    countries,
  ]);

  // Destination Validation
  useEffect(() => {
    const data: string[] = [];

    if (!destinationName) data.push("Name is required");
    if (!destinationCompany) data.push("Company is required");
    if (!destinationAddress) data.push("Address is required");
    if (destinationAddress.length > 45)
      data.push("Max length for address is 45 characters"); // DHL Specific
    if (!destinationCity) data.push("City is required");
    if (!destinationState) data.push("State is required");
    if (!destinationCountry) data.push("Country is required");
    // if (!destinationEmailAddress) data.push("Email address is required");
    if (!destinationTelephoneCountry)
      data.push("Telephone Country is required");
    if (!destinationTelephone) data.push("Telephone is required");

    if (!deliveryDate) data.push("Delivery date is required");

    // Contact validation
    if (
      selectedOrigin &&
      selectedDestination &&
      selectedOrigin._id === selectedDestination._id
    )
      data.push("Destination contact must not be the same as origin contact");

    // Address validation
    if (
      destinationCountry &&
      ["Local", "Nationwide"].includes(shipmentType) &&
      originCountry &&
      destinationCountry._id !== originCountry._id
    )
      data.push("Destination country must be the same as origin country");

    if (
      destinationCountry &&
      shipmentType === "Export" &&
      originCountry &&
      destinationCountry._id === originCountry._id
    )
      data.push("Destination country must not be the same as origin country");

    if (
      destinationState &&
      shipmentType === "Local" &&
      originState &&
      destinationState !== originState
    )
      data.push("Destination state must be the same as origin state");

    if (
      destinationState &&
      shipmentType === "Nationwide" &&
      destinationState === originState
    )
      data.push("Destination state must not be the same as origin state");

    setDestinationIssues(data);
    setDestinationValid(!data.length);
  }, [
    destinationName,
    destinationCompany,
    destinationAddress,
    destinationCity,
    destinationState,
    destinationCountry,
    destinationEmailAddress,
    destinationTelephoneCountry,
    destinationTelephone,
    selectedOrigin,
    selectedDestination,
    deliveryDate,
    shipmentType,
    originCountry,
    originState,
  ]);

  // Cargo Validation
  useEffect(() => {
    const data: string[] = [];

    if (!shipmentCargos.length)
      data.push("At least one shipment cargo is required");

    if (shipmentCargos.length) {
      const totalValue = shipmentCargos.reduce(
        (a, b) => a + b.cargoDeclaredValue,
        0
      );

      if (+totalValue !== +declaredValue)
        data.push("Total cargo value does not match shipment declared value");
    }

    setCargoIssues1(data);
    setCargoIssues1Valid(!data.length);
  }, [shipmentCargos, declaredValue]);

  useEffect(() => {
    const data: string[] = [];

    if (!cargoDescription) data.push("Cargo description is required");
    if (!cargoQuantity) data.push("Cargo quantity is required");
    if (!cargoDeclaredValue) data.push("Cargo declared value is required");
    if (!cargoWeight) data.push("Cargo weight is required");

    if (cargoLength && (Number.isNaN(+cargoLength) || +cargoLength < 1))
      data.push("Cargo length must be greater than or equal to 1");
    if (cargoBreadth && (Number.isNaN(+cargoBreadth) || +cargoBreadth < 1))
      data.push("Cargo breadth must be greater than or equal to 1");
    if (cargoHeight && (Number.isNaN(+cargoHeight) || +cargoHeight < 1))
      data.push("Cargo height must be greater than or equal to 1");

    if (shipmentType === "Export" && !cargoItemCode)
      data.push("Cargo item code is required");

    setCargoIssues2(data);
    setCargoIssues2Valid(!data.length);
  }, [
    cargoDescription,
    cargoQuantity,
    cargoDeclaredValue,
    cargoWeight,
    cargoItemCode,
    shipmentType,
    cargoLength,
    cargoBreadth,
    cargoHeight,
  ]);

  // Cargo useEffects
  useEffect(() => {
    if (typeof shipmentCargoIndex !== "number") return;

    const shipmentCargo = shipmentCargos[shipmentCargoIndex];

    setCargoDescription(shipmentCargo.cargoDescription);
    setCargoItemCode(shipmentCargo.cargoItemCode);
    setCargoQuantity(shipmentCargo.cargoQuantity);
    setCargoDeclaredValue(shipmentCargo.cargoDeclaredValue);
    setCargoPackagingType(shipmentCargo.cargoPackagingType);
    setCargoWeight(shipmentCargo.cargoWeight);
    setCargoWeightMeasure(shipmentCargo.cargoWeightMeasure);
    setCargoLength(shipmentCargo.cargoLength);
    setCargoBreadth(shipmentCargo.cargoBreadth);
    setCargoHeight(shipmentCargo.cargoHeight);
  }, [shipmentCargoIndex, shipmentCargos]);

  useEffect(() => {
    if (!selectedCargo) return;

    setCargoDescription(selectedCargo.Description);
    setCargoItemCode(selectedCargo.HSCode);
  }, [selectedCargo]);

  useEffect(() => {
    if (!cargoPackagingType) {
      setCargoWeight("");
      setCargoWeightMeasure("KG");
      setCargoLength("");
      setCargoBreadth("");
      setCargoHeight("");
    } else {
      setCargoWeight(cargoPackagingType.Weight);
      setCargoWeightMeasure(cargoPackagingType.WeightMeasure);
      setCargoLength(cargoPackagingType.Length);
      setCargoBreadth(cargoPackagingType.Breadth);
      setCargoHeight(cargoPackagingType.Height);
    }
  }, [cargoPackagingType]);

  // Destination useEffects
  useEffect(() => {
    if (!selectedDestinationTelephoneCountryId)
      return setDestinationTelephoneCountry(null);

    setDestinationTelephoneCountry(
      countries.find((ct) => ct._id === selectedDestinationTelephoneCountryId)!
    );
  }, [selectedDestinationTelephoneCountryId, countries]);

  useEffect(() => {
    if (!selectedDestinationTelephone2CountryId)
      return setDestinationTelephone2Country(null);

    setDestinationTelephone2Country(
      countries.find((ct) => ct._id === selectedDestinationTelephone2CountryId)!
    );
  }, [selectedDestinationTelephone2CountryId, countries]);

  useEffect(() => {
    if (selectedDestination) {
      setDestinationName(selectedDestination.Name);
      setDestinationCompany(selectedDestination.Company);
      setDestinationAddress(selectedDestination.Address);
      setDestinationAddress2(selectedDestination.Address2 || "");
      setDestinationAddress3(selectedDestination.Address3 || "");
      setDestinationSuburb(selectedDestination.Suburb || "");
      setDestinationCity(selectedDestination.City);
      setDestinationPostalCode(selectedDestination.PostalCode || "");
      setDestinationState(selectedDestination.State);
      setDestinationCountry(selectedDestination.Country);
      setDestinationEmailAddress(selectedDestination.EmailAddress || "");
      setDestinationTelephoneCountry(selectedDestination.TelephoneCountry);
      setDestinationTelephone(
        +selectedDestination.Telephone.slice(
          selectedDestination.Telephone.length - 10
        )
      );
      setDestinationTelephone2Country(
        selectedDestination.Telephone2Country || null
      );
      setDestinationTelephone2(
        selectedDestination.Telephone2
          ? +selectedDestination.Telephone2.slice(
              selectedDestination.Telephone2.length - 10
            )
          : ""
      );
      setDestinationIsResidential(selectedDestination.Residential);
      setDestinationSaveContact(false);
    } else {
      setDestinationName("");
      setDestinationCompany("");
      setDestinationAddress("");
      setDestinationAddress2("");
      setDestinationAddress3("");
      setDestinationSuburb("");
      setDestinationCity("");
      setDestinationPostalCode("");
      setDestinationState("");
      setDestinationCountry(null);
      setDestinationEmailAddress("");
      setDestinationTelephoneCountry(null);
      setDestinationTelephone("");
      setDestinationTelephone2Country(null);
      setDestinationTelephone2("");
      setDestinationTelephone2("");
      setDestinationIsResidential(false);
      setDestinationSaveContact(false);
    }
  }, [selectedDestination]);

  useEffect(() => {
    if (!destinationCountry) return;

    if (!destinationTelephoneCountry)
      setDestinationTelephoneCountry(destinationCountry);
    if (!destinationTelephone2Country)
      setDestinationTelephone2Country(destinationCountry);
  }, [
    destinationCountry,
    destinationTelephoneCountry,
    destinationTelephone2Country,
  ]);

  // Origin useEffects
  useEffect(() => {
    if (!selectedOriginTelephoneCountryId)
      return setOriginTelephoneCountry(null);

    setOriginTelephoneCountry(
      countries.find((ct) => ct._id === selectedOriginTelephoneCountryId)!
    );
  }, [selectedOriginTelephoneCountryId, countries]);

  useEffect(() => {
    if (!selectedOriginTelephone2CountryId)
      return setOriginTelephone2Country(null);

    setOriginTelephone2Country(
      countries.find((ct) => ct._id === selectedOriginTelephone2CountryId)!
    );
  }, [selectedOriginTelephone2CountryId, countries]);

  useEffect(() => {
    if (selectedOrigin) {
      setOriginName(selectedOrigin.Name);
      setOriginCompany(selectedOrigin.Company);
      setOriginAddress(selectedOrigin.Address);
      setOriginAddress2(selectedOrigin.Address2 || "");
      setOriginAddress3(selectedOrigin.Address3 || "");
      setOriginSuburb(selectedOrigin.Suburb || "");
      setOriginCity(selectedOrigin.City);
      setOriginPostalCode(selectedOrigin.PostalCode || "");
      setOriginState(selectedOrigin.State);
      setOriginCountry(selectedOrigin.Country);
      setOriginEmailAddress(selectedOrigin.EmailAddress || "");
      setOriginTelephoneCountry(selectedOrigin.TelephoneCountry);
      setOriginTelephone(
        +selectedOrigin.Telephone.slice(selectedOrigin.Telephone.length - 10)
      );
      setOriginTelephone2Country(selectedOrigin.Telephone2Country || null);
      setOriginTelephone2(
        selectedOrigin.Telephone2
          ? +selectedOrigin.Telephone2.slice(
              selectedOrigin.Telephone2.length - 10
            )
          : ""
      );
      setOriginIsResidential(selectedOrigin.Residential);
      setOriginSaveContact(false);
    } else {
      setOriginName("");
      setOriginCompany("");
      setOriginAddress("");
      setOriginAddress2("");
      setOriginAddress3("");
      setOriginSuburb("");
      setOriginCity("");
      setOriginPostalCode("");
      setOriginState("");
      setOriginCountry(null);
      setOriginEmailAddress("");
      setOriginTelephoneCountry(null);
      setOriginTelephone("");
      setOriginTelephone2Country(null);
      setOriginTelephone2("");
      setOriginTelephone2("");
      setOriginIsResidential(false);
      setOriginSaveContact(false);
    }
  }, [selectedOrigin]);

  useEffect(() => {
    if (!originCountry) return;

    if (!originTelephoneCountry) setOriginTelephoneCountry(originCountry);
    if (!originTelephone2Country) setOriginTelephone2Country(originCountry);
  }, [originCountry, originTelephoneCountry, originTelephone2Country]);

  useEffect(() => {
    const fillInAddress = (
      autocomplete: any,
      type: "Origin" | "Destination"
    ) => {
      const setAddress =
        type === "Origin" ? setOriginAddress : setDestinationAddress;
      const setAddress2 =
        type === "Origin" ? setOriginAddress2 : setDestinationAddress2;
      const setAddress3 =
        type === "Origin" ? setOriginAddress3 : setDestinationAddress3;
      const setSuburb =
        type === "Origin" ? setOriginSuburb : setDestinationSuburb;
      const setCity = type === "Origin" ? setOriginCity : setDestinationCity;
      const setPostalCode =
        type === "Origin" ? setOriginPostalCode : setDestinationPostalCode;
      const setState = type === "Origin" ? setOriginState : setDestinationState;
      const setCountry =
        type === "Origin" ? setOriginCountry : setDestinationCountry;

      setAddress("");
      setAddress2("");
      setAddress3("");
      setSuburb("");
      setCity("");
      setPostalCode("");
      setState("");
      setCountry(null);

      const place = autocomplete.getPlace();

      console.log(place);

      let street: string = "";
      let route: string = "";

      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];

        if (addressType === "street_number") {
          street = place.address_components[i]["long_name"];
        }

        if (addressType === "route") {
          route = place.address_components[i]["long_name"];
        }

        if (!street && route) setAddress(route);
        if (street && !route) setAddress(street);
        if (street && route) setAddress(`${street}, ${route}`);

        if (addressType === "sublocality_level_1")
          setAddress(place.address_components[i]["long_name"]);

        if (addressType === "neighborhood")
          setSuburb(place.address_components[i]["long_name"]);

        if (addressType === "locality")
          setCity(place.address_components[i]["long_name"]);
        else if (addressType === "postal_town")
          setCity(place.address_components[i]["long_name"]);

        if (addressType === "administrative_area_level_1") {
          setState(place.address_components[i]["long_name"]);
        }

        if (addressType === "country") {
          const country =
            countries.find(
              (country) =>
                country.Code === place.address_components[i]["short_name"]
            ) || null;
          setCountry(country);
        }

        if (addressType === "postal_code")
          setPostalCode(place.address_components[i]["long_name"]);
      }
    };

    if (originAddressRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        originAddressRef.current,
        { types: ["geocode"] }
      );
      autocomplete.setFields(["address_component"]);
      autocomplete.addListener("place_changed", () =>
        fillInAddress(autocomplete, "Origin")
      );
    }

    if (destinationAddressRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        destinationAddressRef.current,
        { types: ["geocode"] }
      );
      autocomplete.setFields(["address_component"]);
      autocomplete.addListener("place_changed", () =>
        fillInAddress(autocomplete, "Destination")
      );
    }
  }, [countries, triggerAddressField]);

  // Set currency to NGN for local and nationwide and USD for export
  useEffect(() => {
    if (!shipmentType || ["Local", "Nationwide"].includes(shipmentType))
      return setDeclaredValueCurrency(
        currencies.find((currency) => currency.Code === "NGN") || null
      );

    if (shipmentType === "Export")
      return setDeclaredValueCurrency(
        currencies.find((currency) => currency.Code === "USD") || null
      );
  }, [shipmentType, currencies]);

  // Set appropriate countries for different shipmentType
  // Local and Nationwide => origin and destination Country(Nigeria)
  // Export => origin country has to be nigeria
  // Import => destination country has to be nigeria
  useEffect(() => {
    if (!shipmentType) return;

    const nigeria = countries.find((country) => country.Code === "NG") || null;

    if (["Local", "Nationwide"].includes(shipmentType)) {
      if (!originCountry) setOriginCountry(nigeria);
      if (!destinationCountry) setDestinationCountry(nigeria);
    }

    if (shipmentType === "Export") {
      if (!originCountry) setOriginCountry(nigeria);
    }

    if (shipmentType === "Import") {
      if (!destinationCountry) setDestinationCountry(nigeria);
    }
  }, [shipmentType, countries, originCountry, destinationCountry]);

  // Load shipment for edit shipment
  useEffect(() => {
    if (!shipmentId) return;

    api_client({
      url: `/shipments/${shipmentId}/full`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        const shipmentData = res.data.data;

        setShipmentDescription(shipmentData.Description);
        setShipmentType(shipmentData.Type);
        setDeclaredValue(shipmentData.DeclaredValue);
        setDeclaredValueCurrency(shipmentData.ValueInsuranceCurrency);
        setCargoInsurance(shipmentData.CargoInsurance);

        setSelectedOrigin(shipmentData.Shipper);
        setPickupDate(getInputDate(shipmentData.PickupDate));
        setSelectedDestination(shipmentData.Cosignee);
        setDeliveryDate(getInputDate(shipmentData.DeliveryDate));

        setShipmentCargos(
          (shipmentData.Cargos as any[]).map((cargo) => ({
            cargoDescription: cargo.Description,
            cargoItemCode: cargo.ItemCode,
            cargoQuantity: cargo.Quantity,
            cargoDeclaredValue: cargo.DeclaredValue,
            cargoPackagingType: cargo.PackagingType || null,
            cargoWeight: cargo.Weight,
            cargoWeightMeasure: cargo.WeightMeasure,
            cargoLength: cargo.Length,
            cargoBreadth: cargo.Breadth,
            cargoHeight: cargo.Height,
          }))
        );
      })
      .catch((err) => {
        // navigate
      })
      .finally(() => {
        setLoading(false);
      });
  }, [shipmentId, accessToken]);

  useEffect(() => {
    fetchCurrencies();
    fetchCountries();
  }, [fetchCurrencies, fetchCountries]);

  const BasicInformation = (
    <div className="new-shipping__form">
      <div className="form-group">
        <label>
          Shipment Description <span className="text-danger">*</span>
        </label>
        <textarea
          placeholder="Enter shipment description"
          cols={30}
          rows={5}
          className="form-input"
          value={shipmentDescription}
          onChange={(e) => setShipmentDescription(e.target.value)}
        ></textarea>
      </div>
      <div className="new-shipping__form-grid-1">
        <div className="form-group">
          <label>
            Shipment Type <span className="text-danger">*</span>
          </label>
          <select
            className="form-select"
            value={shipmentType}
            onChange={(e) => setShipmentType(e.target.value as tShipmentType)}
          >
            <option value="">-- Select Shipment Type --</option>
            {shippingTypes.map((shpType) => (
              <option key={shpType}>{shpType}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>
            Declared Value <span className="text-danger">*</span>
          </label>
          <div className="input-group">
            <button>{declaredValueCurrency?.Code}</button>
            <input
              type="text"
              placeholder="Enter declared value"
              value={declaredValue}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setDeclaredValue(e.target.value)
                    : null
                  : setDeclaredValue("")
              }
            />
          </div>
        </div>
        {!shipmentType || ["Import", "Export"].includes(shipmentType) ? (
          <div className="form-group">
            <label>Cargo Insurance</label>
            <div className="input-group">
              <button>{declaredValueCurrency?.Code}</button>
              <input
                type="text"
                placeholder="Enter cargo insurance"
                value={cargoInsurance}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoInsurance(e.target.value)
                      : null
                    : setCargoInsurance("")
                }
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );

  const Origin = (
    <div className="new-shipping__form">
      <div className="new-shipping__form-grid-1">
        <div className="form-group">
          <div className="label-flex">
            <label>
              Name <span>*</span>
            </label>
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Enter Name"
              value={originName}
              onChange={(e) => setOriginName(e.target.value)}
              onFocus={(e) => {
                setOriginNameFocus(true);
              }}
              onBlur={() => {
                setOriginNameFocus(false);
              }}
            />
            <button onClick={() => openSelectContact(setSelectedOrigin)}>
              <IonIcon icon={searchOutline} />
            </button>
          </div>
          <SearchBox
            focus={originNameFocus}
            url="/contacts?page=1&division=8&search={{search}}"
            search={originName}
            displayKey="Name"
            clickHandler={setSelectedOrigin}
          />
        </div>
        <div className="form-group">
          <label>
            Company <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter company"
            value={originCompany}
            onChange={(e) => setOriginCompany(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Pickup Date <span>*</span>
          </label>
          <input
            type="date"
            className="form-input"
            value={pickupDate}
            onChange={(e) => setPickupDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Address <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address"
            value={originAddress}
            onChange={(e) => setOriginAddress(e.target.value)}
            ref={(el) => {
              originAddressRef.current = el;
              setTriggerAddressField((taf) => !taf);
            }}
          />
        </div>
        <div className="form-group">
          <label>Address 2</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address 2"
            value={originAddress2}
            onChange={(e) => setOriginAddress2(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Address 3</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address 3"
            value={originAddress3}
            onChange={(e) => setOriginAddress3(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Suburb</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter suburb"
            value={originSuburb}
            onChange={(e) => setOriginSuburb(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Postal code</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter postal code"
            value={originPostalCode}
            onChange={(e) => setOriginPostalCode(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Country <span>*</span>
          </label>
          <select
            className="form-select"
            value={originCountry!?._id || ""}
            onChange={compSelectHandler<tCountry>(countries, setOriginCountry)}
          >
            <option value="">-- Select Country --</option>
            {countries.map((cuntry) => (
              <option key={cuntry._id} value={cuntry._id}>
                {cuntry.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>
            State <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter state"
            value={originState}
            onChange={(e) => setOriginState(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            City <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter city"
            value={originCity}
            onChange={(e) => setOriginCity(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email address</label>
          <input
            type="email"
            className="form-input"
            placeholder="Enter email address"
            value={originEmailAddress}
            onChange={(e) => setOriginEmailAddress(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Phone Number <span>*</span>
          </label>
          <div className="input-group">
            <button onClick={() => openOriginTelephoneCountrySelectBox()}>
              {originTelephoneCountry
                ? originTelephoneCountry.TelephoneCode
                : "Select"}{" "}
              <IonIcon icon={chevronDownOutline} />
            </button>
            <input
              type="text"
              placeholder="Enter Phone Number 1"
              value={originTelephone}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setOriginTelephone(+e.target.value)
                    : null
                  : setOriginTelephone("")
              }
              maxLength={10}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Phone Number 2</label>
          <div className="input-group">
            <button onClick={() => openOriginTelephone2CountrySelectBox()}>
              {originTelephone2Country
                ? originTelephone2Country.TelephoneCode
                : "Select"}{" "}
              <IonIcon icon={chevronDownOutline} />
            </button>
            <input
              type="text"
              placeholder="Enter Phone Number 2"
              value={originTelephone2}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setOriginTelephone2(+e.target.value)
                    : null
                  : setOriginTelephone2("")
              }
              maxLength={10}
            />
          </div>
        </div>
        <div className="new-shipping__checks">
          <div className="accept">
            <input
              type="checkbox"
              className="accept__checkbox"
              checked={originIsResidential}
              onChange={(e) => setOriginIsResidential(e.target.checked)}
            />
            <p>Residential</p>
          </div>
          <div className="accept">
            <input
              type="checkbox"
              className="accept__checkbox"
              checked={originSaveContact}
              onChange={(e) => setOriginSaveContact(e.target.checked)}
            />
            <p>Save Contact</p>
          </div>
        </div>
      </div>
    </div>
  );

  const Destination = (
    <div className="new-shipping__form">
      <div className="new-shipping__form-grid-1">
        <div className="form-group">
          <div className="label-flex">
            <label>
              Name <span>*</span>
            </label>
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Enter Name"
              value={destinationName}
              onChange={(e) => setDestinationName(e.target.value)}
              onFocus={(e) => {
                setDestinationNameFocus(true);
              }}
              onBlur={() => {
                setDestinationNameFocus(false);
              }}
            />
            <button onClick={() => openSelectContact(setSelectedDestination)}>
              <IonIcon icon={searchOutline} />
            </button>
          </div>
          <SearchBox
            focus={destinationNameFocus}
            url="/contacts?page=1&division=8&search={{search}}"
            search={destinationName}
            displayKey="Name"
            clickHandler={setSelectedDestination}
          />
        </div>
        <div className="form-group">
          <label>
            Company <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter company"
            value={destinationCompany}
            onChange={(e) => setDestinationCompany(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Delivery Date <span>*</span>
          </label>
          <input
            type="date"
            className="form-input"
            value={deliveryDate}
            onChange={(e) => setDeliveryDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Address <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address"
            value={destinationAddress}
            onChange={(e) => setDestinationAddress(e.target.value)}
            ref={(el) => {
              destinationAddressRef.current = el;
              setTriggerAddressField((taf) => !taf);
            }}
          />
        </div>
        <div className="form-group">
          <label>Address 2</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address 2"
            value={destinationAddress2}
            onChange={(e) => setDestinationAddress2(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Address 3</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address 3"
            value={destinationAddress3}
            onChange={(e) => setDestinationAddress3(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Suburb</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter suburb"
            value={destinationSuburb}
            onChange={(e) => setDestinationSuburb(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Postal code</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter postal code"
            value={destinationPostalCode}
            onChange={(e) => setDestinationPostalCode(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Country <span>*</span>
          </label>
          <select
            className="form-select"
            value={destinationCountry!?._id || ""}
            onChange={compSelectHandler<tCountry>(
              countries,
              setDestinationCountry
            )}
          >
            <option value="">-- Select Country --</option>
            {countries.map((cuntry) => (
              <option key={cuntry._id} value={cuntry._id}>
                {cuntry.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>
            State <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter state"
            value={destinationState}
            onChange={(e) => setDestinationState(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            City <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter city"
            value={destinationCity}
            onChange={(e) => setDestinationCity(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email address</label>
          <input
            type="email"
            className="form-input"
            placeholder="Enter email address"
            value={destinationEmailAddress}
            onChange={(e) => setDestinationEmailAddress(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Phone Number <span>*</span>
          </label>
          <div className="input-group">
            <button onClick={() => openDestinationTelephoneCountrySelectBox()}>
              {destinationTelephoneCountry
                ? destinationTelephoneCountry.TelephoneCode
                : "Select"}{" "}
              <IonIcon icon={chevronDownOutline} />
            </button>
            <input
              type="text"
              placeholder="Enter Phone Number 1"
              value={destinationTelephone}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setDestinationTelephone(+e.target.value)
                    : null
                  : setDestinationTelephone("")
              }
              maxLength={10}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Phone Number 2</label>
          <div
            className={cls(
              "input-group",
              !!selectedDestination && "input-group--disabled"
            )}
          >
            <button onClick={() => openDestinationTelephone2CountrySelectBox()}>
              {destinationTelephone2Country
                ? destinationTelephone2Country.TelephoneCode
                : "Select"}{" "}
              <IonIcon icon={chevronDownOutline} />
            </button>
            <input
              type="text"
              placeholder="Enter Phone Number 2"
              value={destinationTelephone2}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setDestinationTelephone2(+e.target.value)
                    : null
                  : setDestinationTelephone2("")
              }
              maxLength={10}
            />
          </div>
        </div>
        <div className="new-shipping__checks">
          <div className="accept">
            <input
              type="checkbox"
              className="accept__checkbox"
              checked={destinationIsResidential}
              onChange={(e) => setDestinationIsResidential(e.target.checked)}
            />
            <p>Residential</p>
          </div>
          <div className="accept">
            <input
              type="checkbox"
              className="accept__checkbox"
              checked={destinationSaveContact}
              onChange={(e) => setDestinationSaveContact(e.target.checked)}
            />
            <p>Save Contact</p>
          </div>
        </div>
      </div>
    </div>
  );

  const CargoDetails = (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Cargo Description</th>
              <th>Weight</th>
              <th>Value</th>
              <th>Qty</th>
              <th>Dimensions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!shipmentCargos.length ? (
              <tr>
                <td colSpan={10} className="text-center">
                  You have not added any cargo.{" "}
                  <span className="link">Add below</span>
                </td>
              </tr>
            ) : null}
            {shipmentCargos.map((shipmentCargo, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{shipmentCargo.cargoDescription}</td>
                <td>
                  {shipmentCargo.cargoWeight * shipmentCargo.cargoQuantity}{" "}
                  {shipmentCargo.cargoWeightMeasure}
                </td>
                <td>
                  {declaredValueCurrency?.Symbol}{" "}
                  {shipmentCargo.cargoDeclaredValue}
                </td>
                <td>{shipmentCargo.cargoQuantity}</td>
                <td>
                  {`${shipmentCargo.cargoLength} x ${shipmentCargo.cargoBreadth} x 
                  ${shipmentCargo.cargoHeight}`}
                </td>
                <td>
                  <div className="table__actions">
                    <span
                      className="table__action"
                      title="Edit"
                      onClick={() => setShipmentCargoIndex(i)}
                    >
                      <IonIcon icon={createOutline} />
                    </span>
                    <span
                      className="table__action"
                      title="Delete"
                      onClick={() => removeShipmentCargo(i)}
                    >
                      <IonIcon icon={trashOutline} />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="new-shipping__form">
        <div className="new-shipping__form-grid-1">
          <div className="form-group">
            <div className="label-flex">
              <label>
                Cargo Description <span>*</span>
              </label>
            </div>
            <div className={"input-group"}>
              <input
                type="text"
                placeholder="Enter cargo description"
                value={cargoDescription}
                onChange={(e) => setCargoDescription(e.target.value)}
                onFocus={(e) => {
                  setCargoDescriptionFocus(true);
                }}
                onBlur={() => {
                  setCargoDescriptionFocus(false);
                }}
              />
              <button onClick={() => openSelectCargo(setSelectedCargo)}>
                <IonIcon icon={searchOutline} />
              </button>
            </div>
            <SearchBox
              focus={cargoDescriptionFocus}
              url="/cargos?page=1&division=8&search={{search}}"
              search={cargoDescription}
              displayKey="Description"
              clickHandler={setSelectedCargo}
            />
          </div>
          <div className="form-group">
            <label>
              Quantity <span>*</span>
            </label>
            <input
              type="text"
              className="form-input"
              placeholder="Enter quantity"
              value={cargoQuantity}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setCargoQuantity(+e.target.value)
                    : null
                  : setCargoQuantity("")
              }
            />
          </div>
          <div className="form-group">
            <label>
              Declared Value <span>*</span>
            </label>
            <div className="input-group">
              {declaredValueCurrency ? (
                <button>{declaredValueCurrency.Code}</button>
              ) : null}
              <input
                type="text"
                placeholder="Enter declared value"
                value={cargoDeclaredValue}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoDeclaredValue(e.target.value)
                      : null
                    : setCargoDeclaredValue("")
                }
              />
            </div>
          </div>
          <div className="form-group">
            <label>
              Weight <span>*</span>
            </label>
            <div
              className={cls(
                "input-group",
                cargoPackagingType && "input-group--disabled"
              )}
            >
              <input
                type="text"
                placeholder="Enter weight"
                value={cargoWeight}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoWeight(e.target.value)
                      : null
                    : setCargoWeight("")
                }
                disabled={!!cargoPackagingType}
              />
              <button>{cargoWeightMeasure}</button>
            </div>
          </div>
          <div className="form-group">
            <label>Dimensions</label>
            <div className="new-shipping__form-grid-1">
              <input
                type="text"
                className="form-input"
                placeholder="Length"
                value={cargoLength}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoLength(e.target.value)
                      : null
                    : setCargoLength("")
                }
                disabled={!!cargoPackagingType}
              />
              <input
                type="text"
                className="form-input"
                placeholder="Breadth"
                value={cargoBreadth}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoBreadth(e.target.value)
                      : null
                    : setCargoBreadth("")
                }
                disabled={!!cargoPackagingType}
              />
              <input
                type="text"
                className="form-input"
                placeholder="Height"
                value={cargoHeight}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoHeight(e.target.value)
                      : null
                    : setCargoHeight("")
                }
                disabled={!!cargoPackagingType}
              />
            </div>
          </div>
          <div className="flex-bottom">
            <button className="btn btn--info" onClick={addUpdateShipmentCargo}>
              <IonIcon
                icon={
                  typeof shipmentCargoIndex === "number"
                    ? checkmarkOutline
                    : addOutline
                }
              />
              {typeof shipmentCargoIndex === "number" ? "Save" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </>
  );

  const sections = [
    {
      name: "Basic Information",
      hash: "basic-info",
      availableIn: ["Local", "Nationwide", "Import", "Export"],
      show: true,
      component: BasicInformation,
      done: basicInfoValid,
      validations: [basicInfoIssues],
    },
    {
      name: "Origin (Shipper)",
      hash: "origin",
      availableIn: ["Local", "Nationwide", "Import", "Export"],
      show: true,
      component: Origin,
      done: originValid,
      validations: [originIssues],
    },
    {
      name: "Destination (Cosignee)",
      hash: "destination",
      availableIn: ["Local", "Nationwide", "Import", "Export"],
      show: true,
      component: Destination,
      done: destinationValid,
      validations: [destinationIssues],
    },
    {
      name: "Cargo Details",
      hash: "cargo-details",
      availableIn: ["Nationwide", "Import", "Export"],
      show: true,
      component: CargoDetails,
      done: cargoIssues1Valid,
      validations: [cargoIssues1],
    },
  ];

  const availableSections = sections.filter(
    (section) =>
      section.show &&
      (!shipmentType ||
        (shipmentType && section.availableIn.includes(shipmentType)))
  );

  return (
    <>
      {selectContact}

      {originTelephoneCountrySelectBox}
      {originTelephone2CountrySelectBox}

      {destinationTelephoneCountrySelectBox}
      {destinationTelephone2CountrySelectBox}

      {selectCargo}

      {showSpinner ? <Spinner /> : null}

      {alertMessages.length ? (
        <div>
          <div className="success-modal">
            <div className="icon warning">
              <span className="body"></span>
              <span className="dot"></span>
            </div>
            {alertMessages.map((message, i) => (
              <p key={i} className="text-center">
                {message}
              </p>
            ))}
          </div>
          <div className="overlay" onClick={() => setAlertMessages([])}></div>
        </div>
      ) : null}
      {rateInfo ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>Shipping Rate!</h3>
            <p className="text-center">
              The shipping rate for {rateInfo.totalWeight}kg total weight on{" "}
              {rateInfo.pickupDate} is NGN {rateInfo.rate}
            </p>
            <div className="success-modal__btns">
              <button
                className="btn"
                onClick={() => {
                  setRateInfo(null);
                }}
              >
                Back
              </button>
              <button
                className="btn btn--success"
                onClick={() => {
                  setRateInfo(null);
                  setShowConfirmationModal(true);
                }}
              >
                {shipmentId ? "Update" : "Create"} Shipment
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setRateInfo(null)}></div>
        </div>
      ) : null}
      {showSuccessModal ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>Success</h3>
            <p>Shipment {shipmentId ? "updated" : "created"} successfully</p>
            <div className="success-modal__btns">
              <button
                className="btn btn--info"
                onClick={() => {
                  newShipment.current
                    ? navigate(`/edit-shipment/${newShipment.current}`)
                    : window.location.reload();
                }}
              >
                Back
              </button>
              <button
                className="btn btn--success"
                onClick={() => navigate("/shipments")}
              >
                Next
              </button>
            </div>
          </div>
          <div className="overlay"></div>
        </div>
      ) : null}
      {showConfirmationModal ? (
        <div>
          <div className="success-modal">
            <h3>Confirmation</h3>
            <p className="text-center">
              Are you sure you want to proceed with{" "}
              {shipmentId ? "updating" : "creating"} this shipment
            </p>
            <div className="success-modal__btns">
              <button
                className="btn btn--info"
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn--success" onClick={createShipment}>
                Continue
              </button>
            </div>
          </div>
          <div
            className="overlay"
            onClick={() => setShowConfirmationModal(false)}
          ></div>
        </div>
      ) : null}

      <Header />
      <main className="main">
        <div className="container">
          {loading ? (
            <VerticalBarLoader />
          ) : (
            <>
              <div className="new-shipping">
                <aside className="new-shipping__aside">
                  {availableSections.map((section, i) => (
                    <div
                      className={cls("step", section.done && "step--done")}
                      key={i}
                      onClick={() => {
                        document
                          .getElementById(section.hash)
                          ?.scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      <button className="step__btn">
                        <span>{i + 1}</span>
                        <IonIcon icon={checkmarkOutline} />
                      </button>
                      <p>{section.name}</p>
                    </div>
                  ))}
                </aside>
                <section className="new-shipping__main">
                  {availableSections.map((section, i) => (
                    <div
                      className="new-shipping__form-section"
                      key={i}
                      id={section.hash}
                    >
                      <div className="new-shipping__form-header">
                        <p>
                          {i + 1}. {section.name}
                        </p>
                        {!section.done ? (
                          <div className="new-shipping__info">
                            <div className="new-shipping__info-main">
                              <IonIcon icon={alertCircle} />
                            </div>
                            <div className="new-shipping__info-block">
                              <div className="new-shipping__info-block-main">
                                <div>
                                  <h3>{section.name} Validations</h3>
                                  <ul>
                                    {section.validations[0].map((issue, i) => (
                                      <li key={i}>
                                        <IonIcon icon={alertCircle} />
                                        <p>{issue}</p>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="new-shipping__info-block-top"></div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {section.component}
                    </div>
                  ))}
                  <div className="new-shipping__form-section">
                    <div className="new-shipping__actions">
                      <button
                        className="button button--primary"
                        onClick={searchRate}
                      >
                        {shipmentId ? "Get New" : "Get"} Quote
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default withAuth(NewShipping);
