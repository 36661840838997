import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { boatOutline, ellipsisVertical, filterOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { tContacts } from "../../store/types/shipping.types";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import Header from "../../components/Header/Header";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

import { assertNotNull, copyData, getSkip } from "../../utils/func";

const Contacts = () => {
  const { user, accessToken } = useSelector((state: tRootState) => state.user);

  assertNotNull(user);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");

  const [page, setPage] = useState<number>(1);
  const [division] = useState<number>(20);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [contacts, setContacts] = useState<tContacts>([]);

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    let url = `/contacts?page=${page}&division=${division}&search=${search}`;

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setNumRecords(res.data.meta_data.num_records);
          setPagination(res.data.meta_data.pagination);

          setContacts(res.data.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [search, page, division, accessToken, reload]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <>
      <Header />
      <main className="main">
        <div className="container">
          <div className="shipments-header">
            <div className="shipments-header__left">
              <div className="shipments-header__header">
                <h3 className="shipments-header__heading">Manage Contacts</h3>
              </div>
            </div>
            <div className="shipments-header__actions">
              <input
                type="text"
                className="form-input"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button className="btn btn--primary" style={{ flexShrink: 0 }}>
                <IonIcon icon={filterOutline} />
                New Contact
              </button>
            </div>
          </div>

          <div className="table-responsive shipments-table">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Address</th>
                  <th>Region</th>
                  <th>Contact</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={20}>
                      <VerticalBarLoader sm />
                    </td>
                  </tr>
                ) : null}
                {error ? (
                  <tr>
                    <td colSpan={20} className="text-center">
                      Error fetching contacts.{" "}
                      <span
                        className="link"
                        onClick={() => setReload((rl) => !rl)}
                      >
                        Reload
                      </span>
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && page === 1 && !contacts.length ? (
                  <tr>
                    <td colSpan={20} className="text-center">
                      You have no contacts
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && contacts.length
                  ? contacts.map((contact) => (
                      <tr key={contact._id}>
                        <td>
                          <span onClick={() => copyData(contact._id)}>
                            #{contact._id.slice(contact._id.length - 6)}
                          </span>
                        </td>
                        <td>{contact.Name}</td>
                        <td>{contact.Company}</td>
                        <td>
                          <div>
                            <p>
                              <strong>Address Line 1:</strong> {contact.Address}
                            </p>
                            {contact.Address2 ? (
                              <p>
                                <strong>Address Line 2:</strong>{" "}
                                {contact.Address2}
                              </p>
                            ) : null}
                            {contact.Address3 ? (
                              <p>
                                <strong>Address Line 3:</strong>{" "}
                                {contact.Address3}
                              </p>
                            ) : null}
                            {contact.Suburb ? (
                              <p>
                                <strong>Suburb:</strong> {contact.Suburb}
                              </p>
                            ) : null}
                            {contact.PostalCode ? (
                              <p>
                                <strong>Postal Code:</strong>{" "}
                                {contact.PostalCode}
                              </p>
                            ) : null}
                            <p>
                              <strong>City:</strong> {contact.PostalCode}
                            </p>
                          </div>
                        </td>
                        <td>
                          {contact.State}, {contact.Country.Name}
                        </td>
                        <td>
                          <div>
                            <p>
                              <strong>Email:</strong> {contact.EmailAddress}
                            </p>
                            <p>
                              <strong>Telephone 1:</strong> {contact.Telephone}
                            </p>
                            {contact.Telephone2 ? (
                              <p>
                                <strong>Telephone 2:</strong>{" "}
                                {contact.Telephone2}
                              </p>
                            ) : null}
                          </div>
                        </td>
                        <td>
                          <div className="flex-center">
                            <div className="shipments-menu">
                              <div className="shipments-menu__main">
                                <IonIcon icon={ellipsisVertical} />
                              </div>
                              <div className="shipments-menu__block">
                                <div className="shipments-menu__block-main">
                                  <Link
                                    to={`/shipments?contact=${contact._id}`}
                                  >
                                    <IonIcon icon={boatOutline} /> View
                                    Shipments
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>

          <div className="shipments">
            {loading ? <VerticalBarLoader sm /> : null}
            {error ? (
              <div className="text-center">
                Error fetching contacts.{" "}
                <span className="link" onClick={() => setReload((rl) => !rl)}>
                  Reload
                </span>
              </div>
            ) : null}
            {!loading && !error && page === 1 && !contacts.length ? (
              <div className="text-center">You have no contacts</div>
            ) : null}
            {!loading && !error && contacts.length
              ? contacts.map((contact) => (
                  <div className="shipment" key={contact._id}>
                    <div className="shipment__header">
                      <p>
                        <strong>Contact:</strong> #
                        {contact._id.slice(contact._id.length - 6)}
                      </p>
                      <div className="shipment__right">
                        <div className="flex-center">
                          <div className="shipments-menu">
                            <div className="shipments-menu__main">
                              <IonIcon icon={ellipsisVertical} />
                            </div>
                            <div className="shipments-menu__block">
                              <div className="shipments-menu__block-main">
                                <Link to={`/shipments?contact=${contact._id}`}>
                                  <IonIcon icon={boatOutline} /> View Shipments
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shipment__body">
                      <div className="mb-small">
                        <p>
                          <strong>Name:</strong> {contact.Name}
                        </p>
                        <p>
                          <strong>Company:</strong> {contact.Company}
                        </p>
                      </div>
                      <div className="mb-small">
                        <p>
                          <strong>Address Line 1:</strong> {contact.Address}
                        </p>
                        {contact.Address2 ? (
                          <p>
                            <strong>Address Line 2:</strong> {contact.Address2}
                          </p>
                        ) : null}
                        {contact.Address3 ? (
                          <p>
                            <strong>Address Line 3:</strong> {contact.Address3}
                          </p>
                        ) : null}
                        {contact.Suburb ? (
                          <p>
                            <strong>Suburb:</strong> {contact.Suburb}
                          </p>
                        ) : null}
                        {contact.PostalCode ? (
                          <p>
                            <strong>Postal Code:</strong> {contact.PostalCode}
                          </p>
                        ) : null}
                        <p>
                          <strong>City:</strong> {contact.PostalCode}
                        </p>
                        <p>
                          <strong>State:</strong> {contact.State}
                        </p>
                        <p>
                          <strong>Country:</strong> {contact.Country.Name}
                        </p>
                      </div>
                      <div className="mb-small">
                        <p>
                          <strong>Email:</strong> {contact.EmailAddress}
                        </p>
                        <p>
                          <strong>Telephone 1:</strong> {contact.Telephone}
                        </p>
                        {contact.Telephone2 ? (
                          <p>
                            <strong>Telephone 2:</strong> {contact.Telephone2}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>

          {page !== 1 || contacts.length ? (
            <div className="shipments-footer">
              <p>
                Showing {start} to {end} of {numRecords} entries
              </p>
              <div className="pagination">
                {page !== 1 ? (
                  <>
                    <button onClick={() => setPage(1)}>&#171;</button>
                    <button onClick={() => setPage((page) => page - 1)}>
                      &#8249;
                    </button>
                  </>
                ) : null}
                {page === pagination && page !== 1 ? (
                  <button onClick={() => setPage((page) => page - 1)}>
                    {page - 1}
                  </button>
                ) : null}
                <button className="active">{page}</button>
                {page < pagination ? (
                  <button onClick={() => setPage((page) => page + 1)}>
                    {page + 1}
                  </button>
                ) : null}
                {page !== pagination ? (
                  <>
                    <button onClick={() => setPage((page) => page + 1)}>
                      &#8250;
                    </button>
                    <button onClick={() => setPage(pagination)}>&#187;</button>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(Contacts);
