import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tUser } from "./types/app.types";

type UserType = {
  loggedIn: boolean;
  user: tUser | null;
  accessToken: string | null;
};

const UserInitialState: UserType = {
  loggedIn: false,
  user: null,
  accessToken: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: getState<UserType>("user", UserInitialState),
  reducers: {
    login(
      state,
      {
        payload,
      }: PayloadAction<{
        user: tUser;
        accessToken: string;
      }>
    ) {
      state.loggedIn = true;
      state.accessToken = payload.accessToken;
      state.user = payload.user;

      saveState<UserType>("user", current(state));
    },
    updateUserData(state, { payload }: PayloadAction<tUser>) {
      state.user = payload;

      saveState<UserType>("user", current(state));
    },
    clearUser(state) {
      state.loggedIn = false;
      state.user = null;
      state.accessToken = null;

      saveState<UserType>("user", current(state));
    },
  },
});

export const { login, updateUserData, clearUser } = userSlice.actions;

export default userSlice.reducer;
