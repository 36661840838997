import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { Entries } from "../utils/utils.types";
import { tShipmentTypes, tWeightMeasures } from "./types/shipping.types";
import { tCountries, tCurrencies, tOverview } from "./types/app.types";

import { SelectBoxDataType } from "../components/SelectBox/SelectBox";

type CacheType = {
  shippingTypes: tShipmentTypes;
  currencies: tCurrencies;
  countries: tCountries;
  countriesSelectData: SelectBoxDataType;
  weightMeasures: tWeightMeasures;
  overview: tOverview | null;
};

const CacheInitialState: CacheType = {
  shippingTypes: ["Local", "Nationwide", "Export"],
  currencies: [],
  countries: [],
  countriesSelectData: [],
  weightMeasures: ["KG"],
  overview: null,
};

const cacheSlice = createSlice({
  name: "cache",
  initialState: getState<CacheType>("cache", CacheInitialState),
  reducers: {
    updateCurrencies(state, { payload }: PayloadAction<tCurrencies>) {
      state.currencies = payload;

      saveState("cache", current(state));
    },
    updateCountries(
      state,
      {
        payload,
      }: PayloadAction<{
        countries: tCountries;
        countriesSelectData: SelectBoxDataType;
      }>
    ) {
      state.countries = payload.countries;
      state.countriesSelectData = payload.countriesSelectData;

      saveState("cache", current(state));
    },
    updateOverview(state, { payload }: PayloadAction<tOverview>) {
      state.overview = payload;

      saveState("cache", current(state));
    },
    clearcache(state) {
      for (const [k, v] of Object.entries(
        CacheInitialState
      ) as Entries<CacheType>) {
        state[k] = v as any; // Hmm
      }

      saveState("cache", current(state));
    },
  },
});

export const { updateCurrencies, clearcache, updateCountries, updateOverview } =
  cacheSlice.actions;

export default cacheSlice.reducer;
