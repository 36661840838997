import { MouseEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { closeOutline, filterOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { tShipments } from "../../store/types/shipping.types";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";

import Header from "../../components/Header/Header";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";
import ShipmentActions from "../../components/ShipmentActions/ShipmentActions";

import {
  assertNotNull,
  copyData,
  getDate,
  getSkip,
  roundDP,
} from "../../utils/func";

import { ALL_SHIPMENTS_STATUS, DATE_RANGES } from "../../data";

const Shipments = () => {
  const { user, accessToken } = useSelector((state: tRootState) => state.user);
  const { shippingTypes } = useSelector((state: tRootState) => state.cache);

  assertNotNull(user);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");

  const [showFilterModal, setShowFilterModal] = useState(false);

  const [pickupRange, setPickupRange] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [shippingType, setShippingType] = useState("");
  const [status, setStatus] = useState("");

  const [filter, setFilter] = useState<{
    pickupRange: string;
    startDate: string;
    endDate: string;
    shippingType: string;
    status: string;
  } | null>(null);

  const [message, setMessage, clearMessage] = useAlert();

  const [page, setPage] = useState<number>(1);
  const [division] = useState<number>(20);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [shipments, setShipments] = useState<tShipments>([]);

  const handleSearch = (e: MouseEvent<HTMLButtonElement>) => {
    if (pickupRange === "CUSTOM" && (!startDate || !endDate))
      return setMessage(
        "warning",
        "Start date and end date is required for custom filtering"
      );

    setShowFilterModal(false);

    setFilter({
      pickupRange,
      startDate,
      endDate,
      shippingType,
      status,
    });
  };

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    let url = `/shipments?page=${page}&division=${division}&search=${search}`;

    if (filter) {
      url += `${
        filter.pickupRange && filter.pickupRange !== "CUSTOM"
          ? `&pickupRange=${filter.pickupRange}`
          : ""
      }${
        filter.pickupRange === "CUSTOM"
          ? `&dateRange=${filter.startDate}:${filter.endDate}`
          : ""
      }${filter.shippingType ? `&shipmentType=${filter.shippingType}` : ""}${
        filter.status ? `&status=${filter.status}` : ""
      }`;
    }

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setNumRecords(res.data.meta_data.num_records);
          setPagination(res.data.meta_data.pagination);

          setShipments(res.data.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [filter, search, page, division, accessToken, reload]);

  useEffect(() => {
    clearMessage();
  }, [pickupRange, startDate, endDate, shippingType, status, clearMessage]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <>
      <>
        <div
          className={cls("modal modal--xs", showFilterModal && "modal--open")}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Filter Shipments</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => setShowFilterModal(false)}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              <div className="form-group">
                <label>Pickup Range</label>
                <select
                  className="form-select"
                  value={pickupRange}
                  onChange={(e) => setPickupRange(e.target.value)}
                >
                  <option value="">-- Select pickup range --</option>
                  {DATE_RANGES.map((range) => (
                    <option value={range.key} key={range.key}>
                      {range.value}
                    </option>
                  ))}
                </select>
              </div>
              {pickupRange === "CUSTOM" ? (
                <div className="form-grid">
                  <div className="form-group">
                    <label>
                      Start Date <span>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-input"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      End Date <span>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-input"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              ) : null}
              <div className="form-group">
                <label>Shipment Type</label>
                <select
                  className="form-select"
                  value={shippingType}
                  onChange={(e) => setShippingType(e.target.value)}
                >
                  <option value="">-- Select Shipment Type --</option>
                  {shippingTypes.map((shippType) => (
                    <option value={shippType} key={shippType}>
                      {shippType}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>
                  Shipment Status <span>*</span>
                </label>
                <select
                  className="form-select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">-- Select Status --</option>
                  {ALL_SHIPMENTS_STATUS.map((status) => (
                    <option key={status}>{status}</option>
                  ))}
                </select>
              </div>
              {message}
              <button className="button" onClick={handleSearch}>
                <IonIcon icon={filterOutline} /> Filter Shipments
              </button>
            </div>
          </div>
        </div>
        {showFilterModal ? (
          <div
            className="overlay"
            onClick={() => setShowFilterModal(false)}
          ></div>
        ) : null}
      </>
      <Header />
      <main className="main">
        <div className="container">
          <div className="shipments-header">
            <div className="shipments-header__left">
              <div className="shipments-header__header">
                <h3 className="shipments-header__heading">Manage Shipments</h3>
              </div>
            </div>
            <div className="shipments-header__actions">
              <input
                type="text"
                className="form-input"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                className="btn btn--primary"
                style={{ flexShrink: 0 }}
                onClick={() => setShowFilterModal(true)}
              >
                <IonIcon icon={filterOutline} />
                Filter Shipments
              </button>
            </div>
          </div>

          <div className="table-responsive shipments-table">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Item (Weight)</th>
                  <th>Pickup Date</th>
                  <th>Delivery Info</th>
                  <th>Amount (&#8358;)</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={20}>
                      <VerticalBarLoader sm />
                    </td>
                  </tr>
                ) : null}
                {error ? (
                  <tr>
                    <td colSpan={20} className="text-center">
                      Error fetching shipments records.{" "}
                      <span
                        className="link"
                        onClick={() => setReload((rl) => !rl)}
                      >
                        Reload
                      </span>
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && page === 1 && !shipments.length ? (
                  <tr>
                    <td colSpan={20} className="text-center">
                      You have no shipments
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && shipments.length
                  ? shipments.map((shipment) => (
                      <tr key={shipment._id}>
                        <td>
                          <span onClick={() => copyData(shipment._id)}>
                            #{shipment._id.slice(shipment._id.length - 6)}
                          </span>
                        </td>
                        <td>
                          {shipment.Description}{" "}
                          {shipment.TotalWeight ? (
                            <>({roundDP(shipment.TotalWeight, 2)} KG)</>
                          ) : null}
                        </td>
                        <td>
                          {shipment.PickupDate
                            ? getDate(shipment.PickupDate)
                            : ""}
                        </td>
                        <td>
                          <div>
                            <p>
                              <strong>Name:</strong> {shipment.Cosignee.Name}
                            </p>
                            <p>
                              <strong>Telephone:</strong>{" "}
                              {shipment.Cosignee.Telephone}
                            </p>
                            <p>
                              <strong>Address:</strong>{" "}
                              {shipment.Cosignee.Address},{" "}
                              {shipment.Cosignee.State},{" "}
                              {shipment.Cosignee.Country.Name}
                            </p>
                          </div>
                        </td>
                        <td>{roundDP(shipment.TotalIncomeCharge, 2)}</td>
                        <td title={shipment.LastActivity}>
                          {shipment.Status}
                          <br />({shipment.LastActivity.slice(0, 30)}
                          {shipment.LastActivity.length > 30 ? "..." : ""})
                        </td>
                        <td>
                          <ShipmentActions
                            setShipments={setShipments}
                            shipment={shipment}
                            reloadShipments={() => setReload((rl) => !rl)}
                          />
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>

          <div className="shipments">
            {loading ? <VerticalBarLoader sm /> : null}
            {error ? (
              <div className="text-center">
                Error fetching shipments records.{" "}
                <span className="link" onClick={() => setReload((rl) => !rl)}>
                  Reload
                </span>
              </div>
            ) : null}
            {!loading && !error && page === 1 && !shipments.length ? (
              <div className="text-center">You have no shipments</div>
            ) : null}
            {!loading && !error && shipments.length
              ? shipments.map((shipment) => (
                  <div className="shipment" key={shipment._id}>
                    <div className="shipment__header">
                      <p>
                        <strong>Shipment:</strong> #
                        {shipment._id.slice(shipment._id.length - 6)}
                      </p>
                      <div className="shipment__right">
                        <span title={shipment.LastActivity}>
                          {shipment.Status}
                        </span>
                        <ShipmentActions
                          setShipments={setShipments}
                          shipment={shipment}
                        />
                      </div>
                    </div>
                    <div className="shipment__body">
                      <div className="mb-small">
                        <p>
                          <strong>Description:</strong> {shipment.Description}{" "}
                          {shipment.TotalWeight ? (
                            <>({roundDP(shipment.TotalWeight, 2)} KG)</>
                          ) : null}
                        </p>
                        {shipment.PickupDate ? (
                          <p>
                            <strong>Pickup Date:</strong>{" "}
                            {getDate(shipment.PickupDate)}
                          </p>
                        ) : null}
                      </div>
                      <div>
                        <p>
                          <strong>Name:</strong> {shipment.Cosignee.Name}
                        </p>
                        <p>
                          <strong>Telephone:</strong>{" "}
                          {shipment.Cosignee.Telephone}
                        </p>
                        <p>
                          <strong>Address:</strong> {shipment.Cosignee.Address}
                        </p>
                      </div>
                      <p>
                        <strong>Amount:</strong> &#8358;{" "}
                        {roundDP(shipment.TotalIncomeCharge, 2)}
                      </p>
                    </div>
                  </div>
                ))
              : null}
          </div>

          {page !== 1 || shipments.length ? (
            <div className="shipments-footer">
              <p>
                Showing {start} to {end} of {numRecords} entries
              </p>
              <div className="pagination">
                {page !== 1 ? (
                  <>
                    <button onClick={() => setPage(1)}>&#171;</button>
                    <button onClick={() => setPage((page) => page - 1)}>
                      &#8249;
                    </button>
                  </>
                ) : null}
                {page === pagination && page !== 1 ? (
                  <button onClick={() => setPage((page) => page - 1)}>
                    {page - 1}
                  </button>
                ) : null}
                <button className="active">{page}</button>
                {page < pagination ? (
                  <button onClick={() => setPage((page) => page + 1)}>
                    {page + 1}
                  </button>
                ) : null}
                {page !== pagination ? (
                  <>
                    <button onClick={() => setPage((page) => page + 1)}>
                      &#8250;
                    </button>
                    <button onClick={() => setPage(pagination)}>&#187;</button>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(Shipments);
